@tailwind base;
@tailwind components;
@tailwind utilities;
@import "assets/scss/app.scss";
* {
  font-family: "DM Sans", sans-serif;
}
:where(.css-dev-only-do-not-override-mzwlov).ant-picker-dropdown
  .ant-picker-ranges
  .ant-picker-ok {
  // margin: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
  background-color: #1ec2e5 !important;
  border-radius: 6px;
}
.speedometer {
  display: flex;
  justify-content: center;
  min-width: 200px;
  margin-top: 20px;
  margin-bottom: -20px;
}

.Toastify__toast--success {
  color: white !important; /* Change this to your desired font color */
  background-color: #0c912b !important;
}

.chip {
  margin-bottom: 5px !important;
  margin-top: 5px !important;
}
.chip, .singleChip {
  white-space: normal !important;
  word-break: break-word !important; /* Optional: ensures long words break and wrap */
}
// #canvas-container {
//   height: 60vh;
//   width: 60vw;
//   position: relative;
// }

@media (min-width: 768px) {
   #canvas-container {
       height: 170px !important;
       width: 250px !important;
    }
}
